<template>
    <form>
      <loading-overlay
        :active="apiStateLoading"
        :is-full-page="true"
        loader="bars"
      />
      <CModalExtended
        color="dark"
        :closeOnBackdrop="false"
        :title="$t('label.columnConfig')"
        :show.sync="modalActive"
        size="xl"
      >   
          <div>
              <CRow class="m-1" v-if="ModalList?.filter != undefined">
                  <CCol sm="4" lg="4" xl="4">
                      <div class="form-group row">
                          <b class="col-sm-6 col-lg-8 col-xl-8 text-right"><label>{{ `${this.$t('label.filtro')}`}}</label></b>
                      </div>
                  </CCol>
                  <CCol sm="8" lg="8" xl="8"></CCol>
                  <CCol sm="4" lg="4" xl="4">
                      <CSelect
                          :horizontal="{ label: 'col-sm-6 col-lg-8 text-right', input: 'col-sm-6 col-lg-4 col-xl-4 d-flex align-items-center'}"
                          :label="`${this.$t('label.measureUnit')}`" 
                          :options="UnitMeasureWeigthOptions"
                          v-model="ModalList.weightSelected"
                      ></CSelect>
                  </CCol>
              </CRow>
              <CRow class="m-1">
                  <CCol sm="4" lg="4" xl="4">
                      <div class="form-group row">
                          <b class="col-sm-6 col-lg-8 col-xl-8 text-right"><label>{{ `${this.$t('label.columns')}`}}</label></b>
                      </div>
                  </CCol>
                  <CCol sm="4" lg="4" xl="4"></CCol>
                  <CCol sm="4" lg="4" xl="4">
                      <div class="form-group row">
                          <label class="col-sm-6 col-lg-8 col-xl-8 text-right">{{ `${this.$t('label.selectAll')}`}}</label>
                          <div class="col-sm-6 col-lg-4 col-xl-4 d-flex align-items-center ">
                              <CSwitch
                                  color="watch"
                                  variant="3d"
                                  type="checkbox"
                                  :checked.sync="ModalList.selectAll"
                                  @update:checked=" selectAll(ModalList, $event)"
                              />
                          </div>
                      </div>
                  </CCol>
              </CRow>
              <CRow class="m-1">
                  <CCol sm="4" lg="4" xl="4"  v-if="ModalList?.cant != undefined">
                      <div class="form-group row">
                          <label class="col-sm-6 col-lg-8 col-xl-8 text-right">{{ `${this.$t('label.quantity')}`}}</label>
                          <div class="col-sm-6 col-lg-4 col-xl-4 d-flex align-items-center">
                              <CSwitch
                                  color="watch"
                                  variant="3d"
                                  type="checkbox"
                                  :checked.sync="ModalList.cant"
                                  @update:checked="ModalList.cant = $event, ModalList.selectAll = false"
                              />
                          </div>
                      </div>
                  </CCol>
                  <CCol sm="4" lg="4" xl="4"  v-if="ModalList?.packaging != undefined">
                      <div class="form-group row">
                          <label class="col-sm-6 col-lg-8 col-xl-8 text-right">{{ `${this.$t('label.packaging')}`}}</label>
                          <div class="col-sm-6 col-lg-4 col-xl-4 d-flex align-items-center">
                              <CSwitch
                                  color="watch"
                                  variant="3d"
                                  type="checkbox"
                                  :checked.sync="ModalList.packaging"
                                  @update:checked="ModalList.packaging = $event, ModalList.selectAll = false"
                              />
                          </div>
                      </div>
                  </CCol>
                  <CCol sm="4" lg="4" xl="4"  v-if="ModalList?.user != undefined">
                      <div class="form-group row">
                          <label class="col-sm-6 col-lg-8 col-xl-8 text-right">{{ `${this.$t('label.USUARIO')}`}}</label>
                          <div class="col-sm-6 col-lg-4 col-xl-4 d-flex align-items-center">
                              <CSwitch
                                  color="watch"
                                  variant="3d"
                                  type="checkbox"
                                  :checked.sync="ModalList.user"
                                  @update:checked="ModalList.user = $event, ModalList.selectAll = false"
                              />
                          </div>
                      </div>
                  </CCol>
                  <CCol sm="4" lg="4" xl="4"  v-if="ModalList?.device != undefined">
                      <div class="form-group row">
                          <label class="col-sm-6 col-lg-8 col-xl-8 text-right">{{ `${this.$t('label.device')}`}}</label>
                          <div class="col-sm-6 col-lg-4 col-xl-4 d-flex align-items-center">
                              <CSwitch
                                  color="watch"
                                  variant="3d"
                                  type="checkbox"
                                  :checked.sync="ModalList.device"
                                  @update:checked="ModalList.device = $event, ModalList.selectAll = false"
                              />
                          </div>
                      </div>
                  </CCol>
                  <CCol sm="4" lg="4" xl="4"  v-if="ModalList?.confirmation != undefined">
                      <div class="form-group row">
                          <label class="col-sm-6 col-lg-8 col-xl-8 text-right">{{ `${this.$t('label.confirmation')}`}}</label>
                          <div class="col-sm-6 col-lg-4 col-xl-4 d-flex align-items-center">
                              <CSwitch
                                  color="watch"
                                  variant="3d"
                                  type="checkbox"
                                  :checked.sync="ModalList.confirmation"
                                  @update:checked="ModalList.confirmation = $event, ModalList.selectAll = false"
                              />
                          </div>
                      </div>
                  </CCol>
                  <CCol sm="4" lg="4" xl="4"  v-if="ModalList?.status != undefined">
                      <div class="form-group row">
                          <label class="col-sm-6 col-lg-8 col-xl-8 text-right">{{ `${this.$t('label.status')}`}}</label>
                          <div class="col-sm-6 col-lg-4 col-xl-4 d-flex align-items-center">
                              <CSwitch
                                  color="watch"
                                  variant="3d"
                                  type="checkbox"
                                  :checked.sync="ModalList.status"
                                  @update:checked="ModalList.status = $event, ModalList.selectAll = false"
                              />
                          </div>
                      </div>
                  </CCol>
                  <CCol sm="4" lg="4" xl="4"  v-if="ModalList?.registerDate != undefined">
                      <div class="form-group row">
                          <label class="col-sm-6 col-lg-8 col-xl-8 text-right">{{ `${this.$t('label.registerDate')}`}}</label>
                          <div class="col-sm-6 col-lg-4 col-xl-4 d-flex align-items-center">
                              <CSwitch
                                  color="watch"
                                  variant="3d"
                                  type="checkbox"
                                  :checked.sync="ModalList.registerDate"
                                  @update:checked="ModalList.registerDate = $event, ModalList.selectAll = false"
                              />
                          </div>
                      </div>
                  </CCol>
                  <CCol sm="4" lg="4" xl="4"  v-if="ModalList?.location != undefined">
                      <div class="form-group row">
                          <label class="col-sm-6 col-lg-8 col-xl-8 text-right">{{ `${this.$t('label.localizacion')}`}}</label>
                          <div class="col-sm-6 col-lg-4 col-xl-4 d-flex align-items-center">
                              <CSwitch
                                  color="watch"
                                  variant="3d"
                                  type="checkbox"
                                  :checked.sync="ModalList.location"
                                  @update:checked="ModalList.location = $event, ModalList.selectAll = false"
                              />
                          </div>
                      </div>
                  </CCol>
                  <CCol sm="4" lg="4" xl="4"  v-if="ModalList?.crane != undefined">
                      <div class="form-group row">
                          <label class="col-sm-6 col-lg-8 col-xl-8 text-right">{{ `${this.$t('label.crane')}`}}</label>
                          <div class="col-sm-6 col-lg-4 col-xl-4 d-flex align-items-center">
                              <CSwitch
                                  color="watch"
                                  variant="3d"
                                  type="checkbox"
                                  :checked.sync="ModalList.crane"
                                  @update:checked="ModalList.crane = $event, ModalList.selectAll = false"
                              />
                          </div>
                      </div>
                  </CCol>
                  <CCol sm="4" lg="4" xl="4"  v-if="ModalList?.hold != undefined">
                      <div class="form-group row">
                          <label class="col-sm-6 col-lg-8 col-xl-8 text-right">{{ `${this.$t('label.hold')}`}}</label>
                          <div class="col-sm-6 col-lg-4 col-xl-4 d-flex align-items-center">
                              <CSwitch
                                  color="watch"
                                  variant="3d"
                                  type="checkbox"
                                  :checked.sync="ModalList.hold"
                                  @update:checked="ModalList.hold = $event, ModalList.selectAll = false"
                              />
                          </div>
                      </div>
                  </CCol>
                  <CCol sm="4" lg="4" xl="4"  v-if="ModalList?.hatchCover != undefined">
                      <div class="form-group row">
                          <label class="col-sm-6 col-lg-8 col-xl-8 text-right">{{ `${this.$t('label.hatchCover')}`}}</label>
                          <div class="col-sm-6 col-lg-4 col-xl-4 d-flex align-items-center">
                              <CSwitch
                                  color="watch"
                                  variant="3d"
                                  type="checkbox"
                                  :checked.sync="ModalList.hatchCover"
                                  @update:checked="ModalList.hatchCover = $event, ModalList.selectAll = false"
                              />
                          </div>
                      </div>
                  </CCol>
                  <CCol sm="4" lg="4" xl="4"  v-if="ModalList?.consignee != undefined">
                      <div class="form-group row">
                          <label class="col-sm-6 col-lg-8 col-xl-8 text-right">{{ `${this.$t('label.consignee')}`}}</label>
                          <div class="col-sm-6 col-lg-4 col-xl-4 d-flex align-items-center">
                              <CSwitch
                                  color="watch"
                                  variant="3d"
                                  type="checkbox"
                                  :checked.sync="ModalList.consignee"
                                  @update:checked="ModalList.consignee = $event, ModalList.selectAll = false"
                              />
                          </div>
                      </div>
                  </CCol>
                  <CCol sm="4" lg="4" xl="4"  v-if="ModalList?.date != undefined">
                      <div class="form-group row">
                          <label class="col-sm-6 col-lg-8 col-xl-8 text-right">{{ `${this.$t('label.date')}`}}</label>
                          <div class="col-sm-6 col-lg-4 col-xl-4 d-flex align-items-center">
                              <CSwitch
                                  color="watch"
                                  variant="3d"
                                  type="checkbox"
                                  :checked.sync="ModalList.date"
                                  @update:checked="ModalList.date = $event, ModalList.selectAll = false"
                              />
                          </div>
                      </div>
                  </CCol>
                  <CCol sm="4" lg="4" xl="4"  v-if="ModalList?.commodity != undefined">
                      <div class="form-group row">
                          <label class="col-sm-6 col-lg-8 col-xl-8 text-right">{{ `${this.$t('label.commodity')}`}}</label>
                          <div class="col-sm-6 col-lg-4 col-xl-4 d-flex align-items-center">
                              <CSwitch
                                  color="watch"
                                  variant="3d"
                                  type="checkbox"
                                  :checked.sync="ModalList.commodity"
                                  @update:checked="ModalList.commodity = $event, ModalList.selectAll = false"
                              />
                          </div>
                      </div>
                  </CCol>
              </CRow>
          </div>
        <div slot="footer">
            <CButton outline color="wipe" @click="closeModal()">
                <CIcon name="x"/>&nbsp;
                {{$t('button.exit')}}
            </CButton>
        </div>
    </CModalExtended>
    </form>
  </template>
  <script>
  import ModalMixin from '@/_mixins/modal';
  import GeneralMixin from '@/_mixins/general';
  import { required, maxLength, helpers } from 'vuelidate/lib/validators';
  import UpperCase from '@/_validations/uppercase-directive';
  import { DateFormater } from '@/_helpers/funciones';
  
  function data() {
    return {
      isSubmit: false,
      modalActive: false,
      TpEirName: '',
      apiStateLoading: false,
      FeaturesDescription:'',
      rubros: [],
      imdg: [],
      isLoadingMulti: false,
      tabIndex: 0,
      ValidPreviousDate: new Date(),
      ValidLaterDate: new Date(),
      previousDate: new Date(),
      laterDate: new Date(),
      IssueDate: '', 
      FinishDate: '',
      TransactionDate: '',
    
    };
  }
  
  function validateDateRange(date) {
    return date < this.previousDate || date > this.laterDate;
  }
  
  function selectAll(ModalList, $event) {
      if(ModalList?.cant != undefined)  ModalList.cant = $event
      if(ModalList?.user != undefined)  ModalList.user = $event
      if(ModalList?.packaging != undefined)  ModalList.packaging = $event
      if(ModalList?.device != undefined)  ModalList.device = $event
      if(ModalList?.confirmation != undefined)  ModalList.confirmation = $event
      if(ModalList?.status != undefined)  ModalList.status = $event
      if(ModalList?.location != undefined)  ModalList.location = $event
      if(ModalList?.crane != undefined)  ModalList.crane = $event
      if(ModalList?.hold != undefined)  ModalList.hold = $event
      if(ModalList?.hatchCover != undefined)  ModalList.hatchCover = $event
      if(ModalList?.consignee != undefined)  ModalList.consignee = $event
      if(ModalList?.date != undefined)  ModalList.date = $event
      if(ModalList?.commodity != undefined)  ModalList.commodity = $event
  }
  
  function closeModal() {
    this.modalActive = false;
  }
  
  function UnitMeasureWeigthOptions() {
    if(this.ModalList.UnitMeasureWeigth && (this.ModalList.UnitMeasureWeigth?.length != 0)){
      let chart = []
      this.ModalList.UnitMeasureWeigth?.map(function(e){
        chart.push({
          value: e.value,
          label: e.label,
        })
      })
      return chart;
    }
  }
  
  export default {
    name: 'modal-columns',
    components: {
     },
    props: { modal: Boolean, ModalList: Object },
    data,
    mixins: [
      ModalMixin,
      GeneralMixin
    ],
    validations: {
      TpEirName: { required, maxLength: maxLength(30) },
    },
    directives: UpperCase,
    methods: {
      validateDateRange,
      closeModal,
      selectAll
    },
    computed: {
      UnitMeasureWeigthOptions
    },
    watch: {
      modal: function(val){
        this.modalActive = val;
        /*if (this.isEdit==true) {
          this.getdata(this.IncidenceGpoItem);
        }*/
      },
      TransactionDate: async function(val){
        try{
          if(val != ''){
            if (DateFormater.formatDateTimeWithoutSlash(val) < this.ValidPreviousDate ) {
              throw this.$t('label.ErrorAtaPleaseCheck');
            }
            if (DateFormater.formatDateTimeWithoutSlash(val) < this.ValidPreviousDate ) {
              throw this.$t('label.ErrorEtaPleaseCheck');
            }
            if (DateFormater.formatDateTimeWithoutSlash(val) > this.ValidLaterDate ) {
              throw this.$t('label.ErrorAtdPleaseCheck');
            }
            if (DateFormater.formatDateTimeWithoutSlash(val) > this.ValidLaterDate ) {
              throw this.$t('label.ErrorCurrentDatedPleaseCheck');
            }
            if (DateFormater.formatDateTimeWithoutSlash(val) < this.IssueDate) {
                throw this.$t('label.ErrorOrderDateIssuePleaseCheck');
            }
            if (this.FinishDate != this.ValidLaterDate && DateFormater.formatDateTimeWithoutSlash(val) > this.FinishDate) {
                throw this.$t('label.ErrorOrderDateFinishPleaseCheck');
            }
          }
        }catch(e){
          this.notifyError({text: e});
        }
      },
    },
   
  };
  </script>