import { render, staticRenderFns } from "./index-assignment.vue?vue&type=template&id=96a5d010&"
import script from "./index-assignment.vue?vue&type=script&lang=js&"
export * from "./index-assignment.vue?vue&type=script&lang=js&"
import style0 from "@/pages/visita/tabs/reporte/css/reporte.css?vue&type=style&index=0&prod&lang=css&"
import style1 from "./index-assignment.vue?vue&type=style&index=1&id=96a5d010&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports